/**
 * @name Accordion
 * @description 아코디안리스트
 */
import React from 'react'
import styled from 'styled-components'
import {H4, H5, debug, uuid} from 'lib'
import {CSS, ListRenderItem, RenderItem} from 'types'
import {FaChevronDown, FaChevronRight, FaChevronUp} from 'assets/icon'

import {COLOR} from 'styles'

type Props = {
  info: any[]
  style?: CSS
}
export default function Accordion({style, info}: Props) {
  // const
  const _style = Object.assign({}, style)
  const [selected, setSelected] = React.useState(0)
  //*--------------------------------------------------*

  const renderItem = ({item, index}: ListRenderItem) => {
    return (
      <dl key={uuid()}>
        <dt>
          <button
            className="flex"
            onClick={() => {
              const temp = selected === index ? -1 : index
              setSelected(temp)
            }}>
            <H5>{item?.title}</H5>
            <span className="icon">{selected === index ? <FaChevronDown /> : <FaChevronRight />}</span>
          </button>
        </dt>
        {selected === index && (
          <dd>
            <span className="body4">{item?.desc}</span>
          </dd>
        )}
      </dl>
    )
  }
  //*--------------------------------------------------*
  return (
    <Element style={_style}>
      {/* renderItem없을경우디버그 */}
      {renderItem === undefined && debug(info)}
      {!!info && info?.length > 0 && info.map((item, index) => renderItem({item, index}))}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.ul`
  width: 100%;
  dl {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: ${COLOR.white};
    overflow: hidden;
    dt {
      button {
        padding: 12px 16px;
        flex-direction: row;
        justify-content: space-between;
        &:hover {
          background-color: ${COLOR.BLUE7};
        }
        span.icon {
          color: ${COLOR.BLUE_GRAY3};
        }
      }
    }
    dd {
      padding: 12px 16px;
    }
  }
`
/********************************************************
[사용법]

<ListDescription
  info={info1}
  renderItem={({item, index}) => {
    return (
      <dl key={uuid()}>
        <dt>{item?.key}</dt>
        <dd>{item?.value}</dd>
      </dl>
    )
  }}
/>
*********************************************************/
