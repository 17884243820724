/**
 * @name 타이틀
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Index() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 56}} flexdirection="column" alignitems="center" justifycontent="center">
        <Text mode="Body_MR" color={COLOR.gs10} label="금액은 천 원 단위로 충전 가능합니다." />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
`
