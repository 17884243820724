/**
 * @name Button
 * @description 버튼
 */
import React from 'react'
import {COLOR} from 'styles/color'
import styled from 'styled-components'
import {CSS} from 'types'

type Props = {
  params?: {}
  style?: CSS //--------------------------------- 스타일시트
  children?: React.ReactNode //------------------ 자식노드
  padding?: string //---------------------------- 여백
  color?: string //------------------------------ 텍스트색상
  border?: string //----------------------------- 테두리
  borderradius?: string //----------------------- 라운드처리
  backgroundcolor?: string //-------------------- 배경색상
  className?: string //-------------------------- 클래스네임
  fontSize?: string //--------------------------- 폰트사이즈
  borderColor?: string //------------------------ 테두리컬러
  disabled?: boolean //-------------------------- 비활성화
  label?: string //-------------------------------버튼명
  full?: boolean | string //----------------------가득찬버튼
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function Button({style, full, padding, color, border, borderradius, backgroundcolor, className, fontSize, children, disabled, label, onClick}: Props) {
  return (
    <Element
      style={style}
      full={full?.toString()}
      padding={padding}
      className={className}
      color={color}
      border={border}
      borderradius={borderradius}
      backgroundcolor={backgroundcolor}
      fontSize={fontSize}
      // {...props}
      disabled={disabled}
      // style={_style}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        !!onClick && onClick(event)
      }}>
      {children}
      {label || 'Button'}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button<Props>`
  display: inline-block;
  width: ${({full}) => (full === 'true' ? '100%' : 'auto')};
  padding: ${({padding}) => padding || '8px 20px'};
  color: ${({color}) => color || COLOR.white};
  background-color: ${({backgroundcolor}) => backgroundcolor || COLOR.BLUE4};
  border: ${({border}) => border || '0 solid transparent'};
  border-radius: ${({borderradius}) => borderradius || '4px'};
  font-size: ${({fontSize}) => fontSize || '14px'};
  box-sizing: border-box;
  line-height: 24px;
  letter-spacing: -3.5%;
  box-shadow: 1px 1px 3px rgba(56, 65, 74, 0.4);
  transition: all 0.2s cubic-bezier(0.8, 0.07, 0.31, 1);
  &:hover {
    cursor: pointer;
    filter: brightness(1.1);
    /* background-color: ${COLOR.BLUE2}; */
  }
  &:active {
    filter: brightness(0.9);
    /* filter: contrast(150%); */
    /* background-color: ${COLOR.BLUE0}; */
  }
  &:disabled {
    cursor: not-allowed;
    color: ${COLOR.BLUE7};
    background-color: ${COLOR.BLUE8};
    box-shadow: 1px 1px 3px rgba(56, 65, 74, 0.1);
  }
  &:disabled:hover,
  &:disabled:active {
    cursor: not-allowed;
    filter: none;
  }
`
/********************************************************
[사용법]

  <Button />
  <Button
    full
    style={{marginBottom: 20}}
    className="button-class"
    label="axios"
    color={COLOR.white}
    border={`1px solid ${COLOR.BLACK1}`}
    borderradius={'10px'}
    backgroundcolor={COLOR.BLACK}
    fontSize={'24px'}
    onClick={event => onClick(event)}
  />

*********************************************************/
