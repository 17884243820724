/**
 * @name 타이틀
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Index() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 56}} flexdirection="column" alignitems="center" justifycontent="center">
        <Text mode="Body_MR" align="center" color={COLOR.gs10}>
          <p>
            충전 상세 정보 확인 및 충전 중단시 사용할 비밀번호를 생성합니다.
            <br />
            비밀번호 4자리 설정 후 확인 버튼을 눌러 주세요.
          </p>
        </Text>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
`
