/**
 * @name Const
 * @description
 */

// 세션스토리지
export const SESSION_STORAGE = {
  TOKEN: 'EV_TOKEN',
  EMAIL: 'EV_EMAIL',
  PASSWORD: 'EV_PASSWORD',
  BUSINESS_SEQ: 'EV_BUSINESS_SEQ', //------------------비지니스시퀀스
  BUSINESS_CODE: 'EV_BUSINESS_CODE', //-----------------코드
}

/********************************************************


*********************************************************/
