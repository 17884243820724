/**
 * @name .
 * @description
 */
import {useEffect} from 'react'
import {ImageButton, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {charge_stop_check_img} from 'assets/svg'
import {DELAY_TIME, MENU} from 'config'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.CHARGE_DIRECTION)
    }, DELAY_TIME)
  }, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: false, title: '회원 카드 태그', home: true}}>
      <Content>
        <ImageButton
          src={charge_stop_check_img}
          onClick={() => {
            navigate(MENU.CHARGE_DIRECTION)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 0 auto;
`
