/**
 * @name ReduxTookit
 */
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {configureStore} from '@reduxjs/toolkit'
// import logger from 'redux-logger'
// slices
import {contextSlice} from './modules'
// * --------------------------------------------------*
const makeStore = () => {
  const store = configureStore({
    reducer: {
      context: contextSlice, //------------------------컨텍스트
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false}),
    // middleware: getDefaultMiddleware => [...getDefaultMiddleware(), logger],
    devTools: process.env.NODE_ENV === 'development', // 개발자도구 설정
  })
  return store
}
export default makeStore()
export type RootState = any
export type AppDispatch = any
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
/********************************************************
[사용법]

*********************************************************/
