/**
 * @name Button
 * @description 버튼
 */
import React from 'react'
import {COLOR} from 'styles/color'
import styled from 'styled-components'
import {CSS} from 'types'
import {Text} from 'lib'

type Props = {
  params?: {}
  className?: string //---------------------------클래스명
  children?: React.ReactNode //------------------ 자식노드
  label?: string //-------------------------------버튼명
  style?: CSS //--------------------------------- 스타일시트
  color?: string //------------------------------ 텍스트색상
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function PanelButton({className, style, children, onClick}: Props) {
  return (
    <Element
      style={style}
      className={className}
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        !!onClick && onClick(event)
      }}>
      {children}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button<Props>`
  display: block;
  width: 100%;
  height: 136px;
  border-radius: 24px;
  border-radius: 48px;
  border: 2px solid ${COLOR.gs50};
  background: ${COLOR.white};
  /* Shadow 1 */
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  /* box-shadow: 0px 8px 12px 0px rgba(24, 59, 86, 0.12); */
`
/********************************************************
[사용법]



*********************************************************/
