/**
 * @name Loading
 * @description 레이어팝업(외부영역 클릭시 닫힌다.)
 */
import styled from 'styled-components'
import Lottie from 'lottie-react'
import {loading} from 'assets/lottie'

export default function Loading() {
  // const

  //*--------------------------------------------------*
  return (
    <Content>
      <Lottie animationData={loading} width={300} height={300} />
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div``
/********************************************************
[사용법]

*********************************************************/
