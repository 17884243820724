/**
 * @name 충전금액입력
 * @description
 */
import {useEffect} from 'react'
import {Image, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {charge_wait_img1} from 'assets/svg'
// contents
import {DELAY_TIME, MENU} from 'config'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.CHARGE_CONNECT)
    }, DELAY_TIME)
  }, [])
  //*--------------------------------------------------*
  return (
    <App
      gnb={{
        prev: true,
        title: '충전 대기중',
        home: false,
      }}>
      <Content>
        <Image src={charge_wait_img1} width={1000} style={{marginTop: 24}} />
        {/* 확인 */}
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 0 auto;
`
