/**
 * @name Context
 * @description
 */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
/** @initialState */
export const sliceName = 'context' //변경필요
//*--------------------------------------------------*
/** @defineType */
type StateType = {
  isCheck: boolean //--------------------------------------------------- 상기내용을 확인하였습니다.
  isMember: boolean | null //------------------------------------------- 회원/비회원
  chargeType: null | 'amount' | 'power' | 'full' //--------------------- 충전방시선택(금액/전력/만땅)
  fullPrice: number //-------------------------------------------------- 만땅충전금액
  nonMemberPhone: string //--------------------------------------------- 휴대폰번호
  nonMemberPassword: string //------------------------------------------ 비밀번호
  chargePrice: number | string //--------------------------------------- 키패드(금액)
  chargePower: number | string //--------------------------------------- 키패드(충전량)
  member_price: number //----------------------------------------------- 회원충전가격
  result: {
    price: number //---------------------------------------------------- 최종가격
    kwh: number //------------------------------------------------------ 최종kWh
  }
  non_member_price: number //------------------------------------------- 비회원충전가격
  //-------------------------------------------------------------------- 충전상태
  charge_status: {
    ischarge_left: boolean // 왼쪽커넥터충전여부
    ischarge_right: boolean // 오른쪽커넥터충전확인
    leftDevice: number // 왼쪽Device
    rightDevice: number // 오른쪽Device
    selectDevice: 'left' | 'right' | null // 선택된 커넥터
  }
}
//*--------------------------------------------------*
/** @initialState */
const initialState: StateType = {
  // 상기내용을 확인하였습니다.
  isCheck: false,
  isMember: false,
  chargeType: 'amount',
  chargePrice: '0',
  chargePower: '0',
  fullPrice: 50000,
  result: {
    price: 0,
    kwh: 0,
  },
  nonMemberPhone: '010-0000-0000',
  nonMemberPassword: '0000',
  // 회원가격
  member_price: 380,
  non_member_price: 480,
  // 충전상태
  charge_status: {
    //충전여부
    ischarge_left: false,
    ischarge_right: false,
    // 커넉터
    leftDevice: 0,
    rightDevice: 0,
    //selectDevice
    selectDevice: null,
  },
}
//*--------------------------------------------------*
/** @createSlice */
const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    //*--------------------------------------------------*
    // 체크표시
    setInit(state: StateType) {
      state.isCheck = false
      state.chargePrice = '0'
      state.chargePower = '0'
      state.isMember = false
      state.nonMemberPhone = '010-0000-0000'
      state.nonMemberPassword = '0000'
    },
    // 체크표시
    setIsCheck(state: StateType, action: PayloadAction<any>) {
      state.isCheck = action.payload
    },
    // 최종결과
    setResult(state: StateType, action: PayloadAction<any>) {
      state.result = action.payload
    },
    // 회원,비회원
    setIsMember(state: StateType, action: PayloadAction<any>) {
      state.isMember = action.payload
    },
    // 충전방식선택(금액/전력/만땅)
    setChargeType(state: StateType, action: PayloadAction<any>) {
      state.chargeType = action.payload
    },
    //#---------- 충전금액
    setPrice(state: StateType, action: PayloadAction<any>) {
      state.chargePrice = action.payload
    },
    //#---------- 충전금액
    setPower(state: StateType, action: PayloadAction<any>) {
      state.chargePower = action.payload
    },
    // 가격저장
    setPhone(state: StateType, action: PayloadAction<any>) {
      state.nonMemberPhone = action.payload
    },
    // 가격저장
    setPassword(state: StateType, action: PayloadAction<any>) {
      state.nonMemberPassword = action.payload
    },
    // 충전상태
    setChargeStatus(state: StateType, action: PayloadAction<any>) {
      state.charge_status = Object.assign(state.charge_status, action.payload)
      const {selectDevice, leftDevice, rightDevice, ischarge_left, ischarge_right} = state.charge_status
      // 60초가 되면 중지
      const limit = 60
      if (leftDevice >= limit) {
        state.charge_status.ischarge_left = false
        state.charge_status.leftDevice = 0
      }
      if (rightDevice >= limit) {
        state.charge_status.ischarge_right = false
        state.charge_status.rightDevice = 0
      }

      // 로그출력
      const _log = [`selectDevice:${selectDevice} #leftDevice:${leftDevice} charge:${ischarge_left}`, ` #rightDevice:${rightDevice} charge:${ischarge_right}`]
      console.log(`%c${_log} `, `display:block;width:100%;font-size:10px;color:tomato;`)
    },
  },
})
//*--------------------------------------------------*
// export
export const EVENT = {
  TOAST: 'TOAST',
  TOGGLE_FULL_SCREEN: 'TOGGLE_FULL_SCREEN',
  DISPLAY_FULL_SCREEN: 'DISPLAY_FULL_SCREEN',
  DISPLAY_NORMAL_SCREEN: 'DISPLAY_NORMAL_SCREEN',
}
export default slice.reducer
export const nameSpace = sliceName
export const actions = slice.actions
