/**
 * @name ImageButton
 * @description 이미지버튼, hover 시 이미지 변경
 */

import React, {CSSProperties, useState} from 'react'
import styled from 'styled-components'
import {logo} from 'assets/images'
import {uuid} from 'lib/util'

type Props = {
  src?: React.ImgHTMLAttributes<HTMLImageElement>['src']
  hover_src?: React.ImgHTMLAttributes<HTMLImageElement>['src'] //---- hover 시 이미지 바꾸는 속성 추가
  alt?: string
  width?: string | number //----------------------이미지넓이
  height?: string | number //---------------------이미지높이
  className?: string //----------------------- 클래스네임
  buttonStyle?: CSSProperties //-------------- 스타일시트
  style?: CSSProperties //-------------------- 스타일시트
  children?: React.ReactNode //------------------ 자식노드
  // events
  onClick?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
  onMouseEnter?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
  onMouseLeave?: (event: React.MouseEvent<HTMLElement>) => void //------------------------ onClick 이벤트
}
export default function ImageButton({src, onMouseEnter, onMouseLeave, children, hover_src, alt, className, width, height, style, onClick}: Props) {
  // hooks
  const [isHovered, setIsHovered] = useState(false) //---- hover 여부 상태 추가
  const _style = Object.assign({}, !!style && style) as CSSProperties
  //*--------------------------------------------------*
  return (
    <Element
      style={_style}
      className={className}
      onMouseEnter={event => {
        !!onMouseEnter && onMouseEnter(event)
        setIsHovered(true)
      }} //---------------------------------------- 마우스 오버 시 상태 변경
      onMouseLeave={event => {
        !!onMouseLeave && onMouseLeave(event)
        setIsHovered(false)
      }} //---------------------------------------- 마우스 벗어날 때 상태 변경
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        onClick && onClick(event)
      }}>
      {/* 
        @alt 속성값 없으면 랜덤으로 생성
       */}
      <img
        style={{width: width, height: height}}
        src={isHovered && hover_src ? hover_src : src || logo} //---- hover 시 이미지 변경
        alt={alt || uuid(4)}
      />
      {!!children && children}
    </Element>
  )
}
//*--------------------------------------------------*
const Element = styled.button`
  font-size: 0;
  &:focus {
    outline: none;
  }
`

/********************************************************
[사용법]
  // 기본형
  <ImageButton />

  // 커스텀
  <ImageButton 
    onMouseEnter={() => {
      console.log('hover')
    }}
    onMouseLeave={() => {
      console.log('out')
    }}
  src={auth_logo} hover_src={map_marker} alt="이미지버튼" width={60} height={50} />

*********************************************************/
