/**
 * @name 키패드타이틀
 * @description
 */
import {checked, checked_on} from 'assets/svg'
import {Flex, Image, Loop, Text} from 'lib'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const {nonMemberPassword} = useAppSelector(state => state.context)
  const [password, setPassword] = useState<string>('0000')
  const [passwordTitle, setPasswordTitle] = useState<string>(password)
  const [isPassword, setIsPassword] = useState<boolean>(false)
  const key = [
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '초기화', value: 'reset'},
    {label: '0', value: 0},
    {label: '지움', value: 'back'},
  ]
  const keypadFunc = (value: any) => {
    if (password === '0000' && value !== 'back' && value !== 'reset') {
      setPassword(value.toString())
    } else if (value === 'back') {
      if (password.slice(0, -1) === '') {
        setPassword('0000')
        return
      }
      if (password.length > 0) {
        // '지움' 기능, 비어있지 않을 때만 작동
        setPassword(password.slice(0, -1))
      }
    } else if (value === 'reset') {
      // '초기화' 기능
      setPassword('0000')
    } else {
      if (password.length < 4) {
        // 패스워드 길이가 4자리 미만인 경우에만 추가 입력 허용
        setPassword(password + value)
      }
    }
  }
  useEffect(() => {
    if (password && password !== '0000') {
      dispatch(contextAction.setPassword(password))
    }
    setPasswordTitle(isPassword ? '*'.repeat(password.length) : password)
  }, [password, isPassword])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 56}} flexdirection="column" alignitems="flex-start" justifycontent="center">
        {/* 출력값 */}
        <Flex className="amount" alignitems="flex-end" justifycontent="flex-end">
          <Text mode="Headline_SB" color={passwordTitle === '' || passwordTitle === '0000' ? COLOR.gs40 : COLOR.gs10} label={passwordTitle} />
        </Flex>
      </Flex>
      <Flex.End style={{marginBottom: 40}}>
        <button
          style={{display: 'flex', alignItems: 'center'}}
          onClick={() => {
            setIsPassword(val => !val)
          }}>
          <Image src={isPassword ? checked_on : checked} style={{marginRight: 8}} />
          <Text mode="Body_SSB" color={COLOR.gs30} label="비밀번호 숨기기" />
        </button>
      </Flex.End>
      <div className="key-wrapper">
        <Loop
          info={key}
          renderItem={({item}: ListRenderItem) => {
            return (
              <button
                className="item"
                onClick={() => {
                  keypadFunc(item.value)
                }}>
                <span className="label">{item.label}</span>
                {item?.sub && <span className="sub">{item.sub}</span>}
              </button>
            )
          }}
        />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  .amount {
    width: 100%;
    margin-bottom: 24px;
    border-bottom: 1px solid ${COLOR.gs40};
  }
  .key-wrapper {
    display: grid;
    width: 592px;
    margin: 0 auto;
    grid-template-columns: repeat(3, 1fr);
    /* grid-gap: 16px; */
    grid-row-gap: 8px;
    grid-column-gap: 11px;
    /* 버튼영역,키패드 */
    .item {
      /* width: 192px; */
      height: 116px;
      border-radius: 24px;
      color: ${COLOR.gs10};
      background-color: ${COLOR.blue50};
      outline: none;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 60px */
      letter-spacing: -0.4px;
      -webkit-tap-highlight-color: transparent; /* for removing the highlight */
      .sub {
        display: inline-block;
        margin-left: 5px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
      }
      &:active {
        color: ${COLOR.white} !important;
        background-color: ${COLOR.blue30};
      }
    }
  }
`
