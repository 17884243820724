/**
 * @name Config
 * @description 설정파일
 */
export * from './routes'

/**---------------------------------- @name CONFIG */
export const DELAY_TIME = 5000
export const LIMIT_TIME = 60
// export const DELAY_TIME = 3000

/********************************************************
[사용법]


*********************************************************/
