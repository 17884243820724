/**
 * @name 오른쪽커넥터(충전중)
 * @description
 */
import {Flex, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {LIMIT_TIME, MENU} from 'config'
import {COLOR} from 'styles'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
// contents
import {PanelButton} from 'pages/common/components'

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  const {charge_status} = useAppSelector(state => state.context)
  const {selectDevice, leftDevice, rightDevice} = charge_status
  //*--------------------------------------------------*
  // 남은시간계산
  const setRemainTime = () => {
    const time = rightDevice
    const result = LIMIT_TIME - time
    if (time >= LIMIT_TIME - 1) {
      return result.toString()
    }
    return result.toString()
  }
  // 진행중인프로세스
  const setProress = () => {
    const time = rightDevice
    const result = Math.floor((time / LIMIT_TIME) * 100)
    return result.toString()
  }
  //*--------------------------------------------------*
  return (
    <Content>
      <PanelButton
        className="button"
        onClick={() => {
          //출력 용량 안내
          dispatch(contextAction.setChargeStatus({selectDevice: 'right'}))
          navigate(MENU.CHARGE_STATUS, {state: {mode: 'continue'}})
        }}>
        <Flex className="group" flexdirection="column" alignitems="center" justifycontent="center">
          <Text mode="Button_MB" label="충전중" color={COLOR.red10} style={{marginBottom: 10}} />
          <Text mode="Button_MB" label="남은 시간" color={COLOR.gs30} style={{marginTop: 30, marginBottom: 10}} />
          <Flex alignitems="center">
            <Text mode="Headline_MB" color={COLOR.red10} label={setRemainTime() + ' 분'} style={{marginBottom: 20}} />
          </Flex>
          {/* 프로그래스바 */}
          <div className="bg">
            <div className="progress" style={{width: setProress() + '%'}} />
          </div>
        </Flex>
        {/* XX% */}
        <Flex alignitems="center" justifycontent="center" width="100%">
          <Text mode="Display_MB" color={COLOR.red10} label={setProress() + '%'} />
        </Flex>
        <Text mode="Headline_MB" label="자세히 보기" color={COLOR.gs30} />
      </PanelButton>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
  flex: 1;
  /* 버튼프로젝트 */
  .button {
    height: 780px;
    padding: 60px 33px 50px 33px;
  }
  /* 프로그래스바 */
  .bg {
    width: 376px;
    height: 32px;
    overflow: hidden;
    border-radius: 99px;
    background-color: ${COLOR.gs40};
    .progress {
      transition: all 0.5s ease-in-out;
      height: 31px;
      background-color: ${COLOR.green10};
    }
  }
`
