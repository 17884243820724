/**
 * @name 키패드타이틀
 * @description
 */
import {useEffect, useState} from 'react'
import {Flex, Loop, Text, addComma} from 'lib'
import {useAppDispatch} from 'model'
import {contextAction} from 'model/modules'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {ListRenderItem} from 'types'
// contents

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const [power, setPower] = useState<string>('0')
  const key = [
    {label: '10', sub: 'kWh', value: 10},
    {label: '1', value: 1},
    {label: '2', value: 2},
    {label: '3', value: 3},
    {label: '20', sub: 'kWh', value: 20},
    {label: '4', value: 4},
    {label: '5', value: 5},
    {label: '6', value: 6},
    {label: '50', sub: 'kWh', value: 50},
    {label: '7', value: 7},
    {label: '8', value: 8},
    {label: '9', value: 9},
    {label: '완충', value: 'full'},
    {label: '초기화', value: 'reset'},
    {label: '0', value: 0},
    {label: '지움', value: 'back'},
  ]
  const keypadFunc = (value: any) => {
    if (value === 'back') {
      // '지움' 버튼: 마지막 숫자 제거
      setPower(prev => prev.slice(0, -1))
    } else if (value === 'reset') {
      // '초기화' 버튼: 초기화
      setPower('0')
    } else if (value === 'full') {
      //완충
      setPower('100')
    } else {
      setPower(prev => {
        // 10, 20, 50인 경우 현재값에서 더하기
        if ([10, 20, 50].includes(value)) {
          const currentPower = parseInt(prev) || 0
          const newPower = currentPower + value
          return newPower.toString().slice(0, 3)
        } else {
          // 그 외의 경우 (일반 숫자 버튼) 숫자 추가
          const newPower = prev === '0' ? value.toString() : prev + value.toString()
          return newPower.slice(0, 3)
        }
      })
    }
  }

  useEffect(() => {
    dispatch(contextAction.setPower(power))
  }, [power])
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%'}} flexdirection="column" alignitems="flex-start" justifycontent="center">
        <Text mode="Body_LSB" color={COLOR.gs10} label="충전량(kWh)" />
        {/* 출력값 */}
        <Flex className="amount" alignitems="flex-end" justifycontent="flex-end">
          <Text mode="Headline_SB" color={power === '' || power === '00' || power === '0' ? COLOR.gs40 : COLOR.gs10} label={addComma(power)} />
          <Text mode="Headline_SB" color={power === '' || power === '00' || power === '0' ? COLOR.gs40 : COLOR.gs10} label="kWh" style={{marginLeft: 24}} />
        </Flex>
      </Flex>
      <div className="key-wrapper">
        <Loop
          info={key}
          renderItem={({item}: ListRenderItem) => {
            return (
              <button
                className="item"
                onClick={() => {
                  keypadFunc(item.value)
                }}>
                <span className="label">{item.label}</span>
                {item?.sub && <span className="sub">{item.sub}</span>}
              </button>
            )
          }}
        />
      </div>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  margin-top: 156px;
  .amount {
    width: 100%;
    margin-bottom: 64px;
    border-bottom: 1px solid ${COLOR.gs40};
  }
  .key-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    /* grid-gap: 16px; */
    grid-row-gap: 8px;
    grid-column-gap: 11px;
    /* 버튼영역,키패드 */
    .item {
      /* width: 192px; */
      height: 116px;
      border-radius: 24px;
      color: ${COLOR.gs10};
      background-color: ${COLOR.blue50};
      outline: none;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 60px */
      letter-spacing: -0.4px;
      -webkit-tap-highlight-color: transparent; /* for removing the highlight */
      .sub {
        display: inline-block;
        margin-left: 5px;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 30px */
        letter-spacing: -0.4px;
      }
      &:active {
        color: ${COLOR.white} !important;
        background-color: ${COLOR.blue30};
      }
    }
  }
`
