/**
 * @name Table
 * @description 리스트 나열
 */
import React from 'react'
import {COLOR} from 'styles'
import styled from 'styled-components'
import {debug} from 'lib'
import {ClassName, CSS, RenderItem} from 'types'

type Props = {
  info: any[]
  renderHeader?: () => React.ReactNode
  renderItem: RenderItem
  style?: CSS
  itemStyle?: CSS
  className?: ClassName // 클래스적용
  hover?: string // hover시 배경색
}
export default function Table({style, hover, renderHeader, renderItem, className, info}: Props) {
  // const
  const _style = Object.assign({}, style)
  //*--------------------------------------------------*
  return (
    <Content hover={hover} style={_style} className={className}>
      {/* 헤더영역 */}
      <thead>{!!renderHeader && renderHeader()}</thead>
      {/* 본문내용 */}
      <tbody>{!!info && info.length > 0 && info.map((item, index) => !!renderItem && renderItem({item: item, index: index}))}</tbody>
      {/* renderItem없을경우디버그 */}
      {renderItem === undefined && debug(info)}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.table<any>`
  width: 100%;
  /* <!--common--> */
  .l {
    text-align: left;
  }
  .r {
    text-align: right;
  }
  .c {
    text-align: center;
  }
  .auto {
    width: auto;
  }
  tr {
    border-bottom: 1px solid ${COLOR.GRAY5};
    &:hover {
      background-color: ${({hover}) => hover || 'transparent'};
    }
    &:active {
      background-color: ${({hover}) => hover || 'transparent'};
    }
  }

  th,
  td {
    padding-left: 8px;
    padding-right: 8px;
    /* text-align: center; */
  }
  th {
    height: 48px;
    /* padding: 0px 8px; */
    /* border-top: 1px solid ${COLOR.GRAY5}; */
    border-bottom: 1px solid ${COLOR.BLUE_GRAY8};
    text-align: center;
    vertical-align: middle;
    color: ${COLOR.BLACK3};
    font-size: 15px;
    line-height: 24px;
    background-color: ${COLOR.BLUE8};
    // 양쪽에 여백
    &.inner > * {
      padding: 0px 10px;
    }
  }
  td {
    height: 64px;
    vertical-align: middle;
    /* padding-top: 18px;
    padding-bottom: 18px;
     */
    color: ${COLOR.BLACK3};
    font-size: 15px;
    line-height: 24px;
    a {
      color: ${COLOR.BLUE3};
    }
  }
`
/********************************************************
[사용법]
  const info = [
    {col_0: 1, col_1: '장비', col_2: '140,알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트, 알럿 내용 텍스트 ', col_3: '2023.07.23 / 14:50'},
    {col_0: 1, col_1: '장비', col_2: '모터 01가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
    {col_0: 1, col_1: '장비', col_2: '모터 02가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
    {col_0: 1, col_1: '에너지효율', col_2: '모터 02가 작동 중지되었습니다.', col_3: '2023.07.23 / 14:50'},
  ]
  
<Table
  hover="red"
  info={info}
  renderHeader={() => {
    return (
      <React.Fragment>
        <tr>
          <th style={{width: 100}}>중요도</th>
          <th style={{width: 100}}>알림유형</th>
          <th style={{width: 'auto'}}>내용</th>
          <th style={{width: 200}}>Date/Time</th>
        </tr>
      </React.Fragment>
    )
  }}
  renderItem={({item}: ListRenderItem) => {
    return (
      <tr key={`${JSON.stringify(item)}_${index}`}>
        <td className="c">{item?.col_0}</td>
        <td className="">{item?.col_1}</td>
        <td>{item?.col_2}</td>
        <td>{item?.col_3}</td>
      </tr>
    )
  }}
/>
*********************************************************/
