/**
 * @name 충전할커넥터방향을선택해주세요.
 * @description
 */
import {useEffect} from 'react'
import {Image, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {DELAY_TIME, MENU} from 'config'
import {select_member_tag_img1} from 'assets/svg'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.SELECT_MEMBER_TAG_CHECK)
    }, DELAY_TIME)
  }, [])
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '회원 카드 태그', home: true}}>
      <Content>
        <Image src={select_member_tag_img1} />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 24px auto;
`
