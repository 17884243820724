/**
 * @name LayerPopup
 * @description 레이어팝업
 * @check scss>layout.module.scss 참고
 */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {ElementButton} from 'lib'
import {CSS} from 'types'
import {COLOR, layout} from 'styles'
// contents
import {Modal} from 'template'
import {VscClose} from 'assets/icon'

type Props = {
  onClose?: () => void //-------------------------Close
  visible?: boolean //----------------------------Visible
  style?: CSS //----------------------------------Style
  children?: React.ReactNode //-------------------Children
}
export default function LayerPopup({visible, style, onClose, children}: Props) {
  // hooks
  const [_visible, _setVisible] = useState(visible || false)
  // const
  const _style = Object.assign({}, style)
  //*--------------------------------------------------*
  useEffect(() => {
    if (visible === undefined) return
    _setVisible(visible)
  }, [visible])
  //*--------------------------------------------------*
  return (
    <Modal
      visible={_visible}
      setVisible={_setVisible}
      modalClose
      onClose={() => {
        !!onClose && onClose()
      }}>
      <div className={layout.modal}>
        <Content style={_style}>
          <header className="flex">
            <ElementButton
              element={<VscClose size={24} />}
              style={{top: 20, right: 20, width: 24, height: 10}}
              onClick={() => {
                _setVisible(false)
                !!onClose && onClose()
              }}
            />
          </header>
          {children}
        </Content>
      </div>
    </Modal>
  )
}
//*--------------------------------------------------*
const Content = styled.article`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  min-height: 200px;
  padding: 16px 16px 32px;
  border-radius: 8px;
  background-color: ${COLOR.white};
  box-shadow: 0px 6px 10px rgba(6, 25, 56, 0.07);
  /* <!--header--> */
  header {
    margin-bottom: 6px;
    align-items: flex-end;
  }
  /* <!--main--> */
  main {
  }
`
/********************************************************
[사용법]

  const [visible, setVisible] = useState(false)

  <LayerPopup
    visible={visible}
    onClose={() => {
      console.log('close')
      setVisible(false)
    }}>
    <div>컨텐츠내용</div>
  </LayerPopup>
*********************************************************/
