/**
 * @name Images
 */

/**---------------------------------- @name Common */
export const logo = require('./common/acelo.png')
export const auth_logo = require('./common/auth_logo.png')
export const crocus = require('./common/crocus.png')
export const acelo = require('./common/acelo.png')

/**---------------------------------- @name Icon */

/**---------------------------------- @name Pages */
export const ad_img1 = require('./pages/ad_img1.png')
export const ad_img2 = require('./pages/ad_img2.png')
export const ad_img3 = require('./pages/ad_img3.png')
export const ad_img4 = require('./pages/ad_img4.png')
export const output_capacity_img1 = require('./pages/output_capacity_img1.png')
export const charge_direction_img1 = require('./pages/charge_direction_img1.png')
