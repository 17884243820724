/**
 * @name CountUp
 * @description
 */
import React, {useEffect, useRef} from 'react'
import {animate} from 'framer-motion'

type Props = {
  from: number
  to: number
  style?: React.CSSProperties //----------------- StyleSheet
  [key: string]: any
}
export default function CountUp({from, to}: Props) {
  // hooks
  const nodeRef = useRef() as any
  //*--------------------------------------------------*

  useEffect(() => {
    const node = nodeRef.current as any
    const controls = animate(from, to, {
      duration: 1,
      onUpdate(value) {
        node.textContent = Math.floor(value).toLocaleString()
      },
    })

    return () => controls.stop()
  }, [])

  return <span ref={nodeRef} />
}
