/**
 * @name App
 * @description
 */
import React, {useEffect} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
import {CSS} from 'types'
import {Events, Flex, Image, ImageButton, Text, useRoutes} from 'lib'
import {top_home_button, top_prev_button, warning} from 'assets/svg'
import {MENU} from 'config'
import {AnimatePresence, motion} from 'framer-motion'
import {CONTEXT_EVENT, contextAction} from 'model/modules'
// components
import Top from './top'
import Footer from './footer'
import {useAppDispatch} from 'model'

type Props = {
  loading?: boolean | null //------------- 로딩중
  padding?: number | string //------------ 패딩
  style?: CSS //-------------------------- 스타일
  bgcolor?: string //--------------------- 배경색상
  gnb?:
    | {
        prev?: boolean
        title?: string
        home?: boolean
      }
    | undefined
  children?: React.ReactNode | JSX.Element[] | undefined
  [key: string]: any
}
export default function App({children, gnb, bgcolor, padding, style}: Props) {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  const [toast, setToast] = React.useState('')
  const [isToast, setIsToast] = React.useState(false)
  //*--------------------------------------------------*
  // 트랜지션 설정
  const fadeInOut = {
    hidden: {
      opacity: 0,
      y: '-100%',
    },
    visible: {
      opacity: 1,
      y: '0%',
      transition: {
        duration: 0.3,
      },
    },
    exit: {
      opacity: 0,
      y: '-100%',
      transition: {
        delay: 2.7, // 3초 중 0.3초는 fade-in에 사용하므로 나머지 시간 동안 display되도록 설정
        duration: 0.3,
      },
    },
  }
  const onToast = (str: string) => {
    setToast(str)
    setIsToast(true)
    setTimeout(() => {
      setIsToast(false)
    }, 3000)
  }
  //*--------------------------------------------------*
  useEffect(() => {
    // 마우스 우클릭 방지
    function handleContextMenu(e: any) {
      e.preventDefault() // prevents the default right-click menu from appearing
    }
    Events.addListener(CONTEXT_EVENT.TOAST, onToast)
    document.addEventListener('contextmenu', handleContextMenu)
    return () => {
      Events.removeListener(CONTEXT_EVENT.TOAST, onToast)
      document.removeEventListener('contextmenu', handleContextMenu)
    }
  }, [])
  //*--------------------------------------------------*
  return (
    <Content style={style} bgcolor={bgcolor} padding={padding}>
      {/* main */}
      <main className="container">
        {/* top */}
        <Top />
        {/* gnb */}
        {!!gnb && (
          <Flex className="header" alignitems="center" justifycontent="space-between">
            {gnb?.prev ? (
              <ImageButton
                onClick={() => {
                  navigate(-1)
                }}
                src={top_prev_button}
              />
            ) : (
              <span style={{width: 96}} />
            )}
            <Text mode="Headline_SB" color={COLOR.gs10} label={gnb?.title || '출력 용량 안내'} />
            {gnb?.home ? (
              <ImageButton
                onClick={() => {
                  dispatch(contextAction.setInit())
                  navigate(MENU.CHARGE_DIRECTION)
                }}
                src={top_home_button}
              />
            ) : (
              <span style={{width: 96}} />
            )}
          </Flex>
        )}
        <AnimatePresence>
          {isToast && (
            <motion.aside initial="hidden" animate="visible" exit="exit" variants={fadeInOut} className="toast">
              <Image src={warning} style={{marginLeft: 24, marginRight: 8}} />
              <Text mode="Body_SSB" color={COLOR.white} label={toast} />
            </motion.aside>
          )}
        </AnimatePresence>
        <div className={`children`}>{children}</div>
      </main>
      {/* 푸터 */}
      <Footer />
    </Content>
  )
}
const Content = styled.main<Props>`
  /* 컨텐츠 */
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: calc(100vh - 480px);
  background-color: ${({bgcolor}) => bgcolor || COLOR.gs70};
  /* article */
  aside.toast {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    top: 214px;
    left: 40px;
    right: 40px;
    /* width: 100%; */
    height: 64px;
    border-radius: 8px;
    background: ${COLOR.gs10};
  }
  /* article */
  main.container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    /* header */
    > .header {
      margin-top: 14px;
      padding-left: 40px;
      padding-right: 40px;
    }
    .children {
      box-sizing: border-box;
      padding: ${({padding}) => padding || '0px'};
      /* height: var(--CONTAINER_HEIGHT); */ /* 패널형태 */
    }
  }
`
/********************************************************
[사용법]

## toast 팝업
  Events.emit(CONTEXT_EVENT.TOAST, '동의하고 계속하기 체크해주세요')

*********************************************************/
