/**
 * @name Flex
 * @param {string} direction - 방향
 * @param {string} justifycontent - 왼,오른쪽정렬
 * @param {string} alignitems - 높이정렬
 * @param {string} children - 자식노드
 * @param {boolean} outline - border표시
 * @description
 */
import React from 'react'
import styled from 'styled-components'
import {CSS} from 'types'

type Props = {
  className?: string
  width?: string | '100%'
  flexwrap?: 'nowrap' | 'wrap' | 'wrap-reverse'
  flexdirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  justifycontent?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly'
  alignitems?: 'flex-start' | 'center' | 'flex-end' | 'stretch' | 'baseline'
  children?: React.ReactNode
  outline?: boolean //----------------------- 테두리
  style?: CSS //----------------------------- 아이콘스타일
}
function Flex({style, width, flexwrap, outline, alignitems, justifycontent, flexdirection, className, children}: Props) {
  return (
    <Content className={className} outline={outline} alignitems={alignitems} justifycontent={justifycontent} flexdirection={flexdirection} flexwrap={flexwrap} style={style} width={width}>
      {children}
    </Content>
  )
}
function Row(props: Props) {
  return <Flex flexdirection="row" alignitems="center" {...props} />
}
function Column(props: Props) {
  return <Flex flexdirection="column" alignitems="center" {...props} />
}
function SpaceBetween(props: Props) {
  return <Flex flexdirection="row" justifycontent="space-between" alignitems="center" {...props} />
}
function Start(props: Props) {
  return <Flex justifycontent="flex-start" alignitems="center" {...props} />
}
function Center(props: Props) {
  return <Flex justifycontent="center" alignitems="center" {...props} />
}
function End(props: Props) {
  return <Flex justifycontent="flex-end" alignitems="center" {...props} />
}
Flex.Row = Row
Flex.Column = Column
Flex.SpaceBetween = SpaceBetween
Flex.Start = Start
Flex.Center = Center
Flex.End = End
export default Flex

//*--------------------------------------------------*
const Content = styled.section<Props>`
  display: flex;
  width: ${({width}) => width || 'auto'};
  border: ${({outline}) => (outline ? '1px dashed #000' : 'none')};
  flex-direction: ${({flexdirection}) => flexdirection || 'row'};
  flex-wrap: ${({flexwrap}) => flexwrap || 'nowrap'};
  justify-content: ${({justifycontent}) => justifycontent || 'flex-start'};
  align-items: ${({alignitems}) => alignitems || 'flex-start'};
`
/********************************************************
[사용법]

  <Flex outline flexdirection="row" justifycontent="flex-end" alignitems="center">
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, marginLeft: 10, background: 'red'}}>test</div>
    <div style={{width: 100, height: 300, background: 'blue'}}>test</div>
  </Flex>

  // ROW
  <Flex.Row alignitems="flex-start">
    <div style={{height: 100}}>111</div>
    <div style={{height: 50}}>222</div>
    <div>33</div>
  </Flex.Row>

  //Column
  <Flex.Column alignitems="flex-start">
    <div style={{height: 100}}>111</div>
    <div style={{height: 50}}>222</div>
  <div>33</div>
*********************************************************/
