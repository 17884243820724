/**
 * @name Masonry
 * @description
 */
import {uuid} from 'lib'
import React from 'react'
import styled from 'styled-components'
import {ClassName} from 'types'

type Props = {
  columncount?: number
  columngap?: number
  className?: ClassName
  children?: React.ReactNode //------------------ children
  info?: any[] | undefined
  renderItem?: (props: {item: any; index: number}) => React.ReactNode // 렌더링할 컴포넌트(리턴값)
}

export default function Masonry({className, columncount, columngap, children, info, renderItem}: Props) {
  // hooks
  return (
    <MasonryContainer className={className} columncount={columncount} columngap={columngap}>
      {!!children && children}
      {!!info &&
        info?.length > 0 &&
        info.map((item, index) => {
          return <React.Fragment key={uuid()}>{!!renderItem && renderItem({item: item, index: index})}</React.Fragment>
        })}
    </MasonryContainer>
  )
}
//*--------------------------------------------------*

const MasonryContainer = styled.div<Props>`
  width: 100%;
  margin: 0 auto;
  column-count: ${({columncount}) => columncount || 4};
  column-gap: ${({columngap}) => columngap + 'px' || 20};
  /* column-gap: 0; */
  > * {
    break-inside: 'avoid';
  }
`
/********************************************************
[사용법]
- 방법1
  const items = Array(20).fill(0).map((_, i) => ({
    content: `Item ${i + 1}`,
    height: Math.random() * 300 + 50, // Random height between 50 and 150
    backgroundColor: randomColor(),
  }))

  <Masonry
    className={'masonry'}
    columngap={30}
    columncount={3}
    info={items}
    renderItem={({item, index}: ListRenderItem) => {
      return (
        <div
          style={{
            marginBottom: 20,
            height: item.height,
            backgroundColor: item.backgroundColor,
            breakInside: 'avoid', // 필수필요!!
          }}>
          {item.content}
        </div>
      )
    }}
  />

- 방법2
  <Masonry columngap={30} columncount={5}>
    {items.map((item, i) => (
      <MasonryItem key={i} height={item.height} backgroundColor={item?.backgroundColor}>
        {item.content}
      </MasonryItem>
    ))}
  </Masonry>

*********************************************************/
