/**
 * @name 회원선택
 * @description
 */
import {Flex, Image, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {COLOR} from 'styles'
import {MENU} from 'config'
import {select_member_img1} from 'assets/svg'
// contents
import {NextButton} from 'pages/common/components'
import {useAppDispatch} from 'model'
import {contextAction} from 'model/modules'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  // hooks
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '회원 선택', home: true}}>
      <Content>
        <Flex justifycontent="center" alignitems="center">
          <Image src={select_member_img1} />
        </Flex>
        <NextButton
          className={'auto'}
          bgcolor={COLOR.blue30}
          style={{width: 650, height: 248, marginTop: 50}}
          label="회원"
          onClick={() => {
            dispatch(contextAction.setIsMember(true))
            navigate(MENU.SELECT_MEMBER_TAG)
          }}>
          <Flex flexdirection="column" justifycontent="center" alignitems="center">
            <Text mode="Body_MR" label="회원카드 태그 / 번호입력" color={COLOR.blue100} />
            <Text mode="Body_MR" label="환경부" color={COLOR.blue100} />
          </Flex>
        </NextButton>
        <NextButton
          className={'auto'}
          bgcolor={COLOR.gs30}
          style={{width: 650, height: 248, marginTop: 50}}
          label="비회원"
          onClick={() => {
            dispatch(contextAction.setIsMember(false))
            navigate(MENU.NON_MEMBER_PHONE)
            // Events.emit(CONTEXT_EVENT.TOAST, '회원만 선택 가능합니다.')
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 60px auto 0;
  .size-button {
    width: 100%;
    height: 100%;
    margin-top: 70px;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
`
