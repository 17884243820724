/**
 * @name Icon
 * @description 레티나,디스플레이대응 2배수이미지
 */
import styled from 'styled-components'

type Props = {
  width?: string
  height?: string
  margin?: string
  padding?: string
  [key: string]: any
}
export default function Icon({style, src, width, height, margin, padding}: Props) {
  return <Element src={src} width={width} height={height} margin={margin} padding={padding} style={style} />
}
//*--------------------------------------------------*
const Element = styled.img<Props>`
  width: ${({width}) => width || '24px'};
  height: ${({height}) => height || '24px'};
  margin: ${({margin}) => margin || '0'};
  padding: ${({padding}) => padding || '0'};
`

/********************************************************
[사용법]

<Icon src={common_logo} />

*********************************************************/
