/**
 * @name 충전방식선택
 * @description
 */
import {Flex, ImageButton, Text, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
import {charge_type_img1, charge_type_img2, charge_type_img3} from 'assets/svg'
import {COLOR} from 'styles'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
// contents
export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  const dispatch = useAppDispatch()
  const {isMember} = useAppSelector(state => state.context)
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: false, title: '충전 방식 선택', home: true}}>
      <Content>
        <Flex flexdirection="column" alignitems="center" justifycontent="center">
          <Text style={{marginTop: 42, marginBottom: 103}} mode="Body_MSB" label="충전하실 방식을 선택해 주세요. " color={COLOR.gs10} />
          <Flex>
            <ImageButton
              src={charge_type_img1}
              onClick={() => {
                dispatch(contextAction.setChargeType('amount'))
                navigate(MENU.CHARGE_PAY_AMOUNT)
              }}
            />
            <ImageButton
              onClick={() => {
                dispatch(contextAction.setChargeType('power'))
                navigate(MENU.CHARGE_PAY_POWER)
              }}
              src={charge_type_img2}
            />
          </Flex>
          <ImageButton
            onClick={() => {
              dispatch(contextAction.setChargeType('full'))
              if (isMember) {
                navigate(MENU.CHARGE_WAIT)
              } else {
                navigate(MENU.CHARGE_PAY_FULL)
              }
            }}
            src={charge_type_img3}
          />
        </Flex>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 0 auto;
`
