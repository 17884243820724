import * as To from 'pages'
import {GnbMenuType, MenuRoutesType} from 'types'

/*===================================================================[메뉴] */
export const MENU = {
  /*---공통---------*/
  HOME: '/',
  NOTICE: '/notice',
  CHARGE_DIRECTION: '/charge-direction',
  OUTPUT_CAPACITY: '/output-capacity',
  SELECT_MEMBER: '/select-member',
  SELECT_MEMBER_TAG: '/select-member-tag',
  SELECT_MEMBER_TAG_CHECK: '/select-member-tag-check',
  /*---비회원---------*/
  NON_MEMBER_PHONE: '/non-member-phone',
  NON_MEMBER_PASSWORD: '/non-member-password',
  /*---회원---------*/
  CHARGE_TYPE: '/charge-type',
  CHARGE_START: '/charge-start',
  CHARGE_WAIT: '/charge-wait',
  CHARGE_CONNECT: '/charge-connect',
  CHARGE_STATUS: '/charge-status',
  CHARGE_COMPLETE: '/charge-complete',
  CHARGE_STOP: '/charge-stop',
  CHARGE_STOP_CHECK: '/charge-stop-check',
  /*---충전방식선택---------*/
  CHARGE_PAY_PRECESS: '/charge-pay-process',
  CHARGE_PAY_AMOUNT: '/charge-pay-amount',
  CHARGE_PAY_FULL: '/charge-pay-full',
  CHARGE_PAY_COMPLETE: '/charge-pay-complete',
  CHARGE_PAY_POWER: '/charge-pay-power',

  /*---가이드---------*/
  GUIDE: '/guide',
  /*---ETC---------*/
  TEMPLATE: '/template',
  //*--------------------------------------------------*
}
/*===================================================================[라우팅] */
export const MenuRoutes: MenuRoutesType[] = MENU && [
  /*---홈---------*/
  {path: MENU.HOME, element: <To.ChargeDirection />},
  {path: MENU.NOTICE, element: <To.Notice />},
  {path: MENU.CHARGE_DIRECTION, element: <To.ChargeDirection />},
  {path: MENU.OUTPUT_CAPACITY, element: <To.OutputCapacity />},
  {path: MENU.SELECT_MEMBER, element: <To.SelectMember />},
  {path: MENU.SELECT_MEMBER_TAG, element: <To.SelectMemberTag />},
  {path: MENU.SELECT_MEMBER_TAG_CHECK, element: <To.SelectMemberTagCheck />},
  // 충전방식선택
  {path: MENU.CHARGE_TYPE, element: <To.ChargeType />} /*충전방식 선택*/,
  {path: MENU.CHARGE_PAY_PRECESS, element: <To.ChargePayPrecess />} /*결제진행중*/,
  {path: MENU.CHARGE_PAY_COMPLETE, element: <To.ChargePayComplete />} /*결제완료*/,
  {path: MENU.CHARGE_PAY_AMOUNT, element: <To.ChargePayAmount />} /*충전금액 입력*/,
  {path: MENU.CHARGE_PAY_FULL, element: <To.ChargePayFull />} /*완전충전*/,
  {path: MENU.CHARGE_PAY_POWER, element: <To.ChargePayPower />} /*완전전력*/,
  //
  {path: MENU.CHARGE_START, element: <To.ChargeStart />} /*충전하기*/,
  {path: MENU.CHARGE_WAIT, element: <To.ChargeWait />} /*충전대기중*/,
  {path: MENU.CHARGE_CONNECT, element: <To.ChargeConnect />} /*커넥팅연결*/,
  {path: MENU.CHARGE_STATUS, element: <To.ChargeStatus />} /*충전현황*/,
  {path: MENU.CHARGE_COMPLETE, element: <To.ChargeComplete />} /*충전현황*/,
  {path: MENU.CHARGE_STOP, element: <To.ChargeStop />} /*충전중단*/,
  {path: MENU.CHARGE_STOP_CHECK, element: <To.ChargeStopCheck />} /*충전중단태그체크*/,
  {path: MENU.NON_MEMBER_PHONE, element: <To.NonMemberPhone />} /*충전중단태그체크*/,
  {path: MENU.NON_MEMBER_PASSWORD, element: <To.NonMemberPassword />} /*충전중단태그체크*/,
]
/*===================================================================[GNB] */
export const GNB_MENU: GnbMenuType[] = MENU && []
