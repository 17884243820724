/**
 * @name 충전금액입력
 * @description
 */
import {useEffect} from 'react'
import {Image, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {charge_stop_img} from 'assets/svg'
import {MENU} from 'config'
import {COLOR} from 'styles'
import {useAppDispatch, useAppSelector} from 'model'
import {contextAction} from 'model/modules'
// contents
import {NextButton} from 'pages/common/components'

export default function Index() {
  // hooks
  const dispatch = useAppDispatch()
  const {navigate} = useRoutes()
  const {isMember, charge_status} = useAppSelector(state => state.context)
  useEffect(() => {}, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <Image src={charge_stop_img} width={1000} style={{marginTop: 24}} />
        {/* 확인 */}
        <NextButton
          className={'auto'}
          style={{width: 830, marginTop: 100, backgroundColor: COLOR.red10}}
          label="네, 충전을 중지하겠습니다."
          onClick={() => {
            let temp
            // 왼쪽
            if (charge_status?.selectDevice === 'left') {
              temp = {selectDevice: null, ischarge_left: false, leftDevice: 0}
            }
            // 오른쪽
            if (charge_status?.selectDevice === 'right') {
              temp = {selectDevice: null, ischarge_right: false, rightDevice: 0}
            }
            dispatch(contextAction.setChargeStatus(temp))
            if (isMember) {
              navigate(MENU.CHARGE_STOP_CHECK)
            } else {
              navigate(MENU.HOME)
            }
            setTimeout(() => {
              dispatch(contextAction.setInit())
            }, 500)
          }}
        />
        <NextButton
          className={'auto'}
          color={COLOR.gs30}
          isshadow={'false'}
          style={{width: 830, marginTop: 32, backgroundColor: 'transparent'}}
          label="아니요, 계속 충전할게요."
          onClick={() => {
            navigate(-1)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 0 auto;
`
