/**
 * @name 충전하기
 * @description
 */
import {useEffect, useState} from 'react'
import {Flex, Text, addComma, debug, useRoutes} from 'lib'
import styled from 'styled-components'
import {App} from 'template'
import {MENU} from 'config'
import {useAppDispatch, useAppSelector} from 'model'
import {COLOR} from 'styles'
// contents
import {NextButton} from 'pages/common/components'
import {contextAction} from 'model/modules'

export default function Index() {
  // hooks
  const {isMember, chargeType, member_price, non_member_price, chargePower, chargePrice, fullPrice} = useAppSelector(state => state.context)
  const {navigate} = useRoutes()
  const [price, setPrice] = useState(0)
  const [kwh, setKwh] = useState(0)
  const dispatch = useAppDispatch()
  //*--------------------------------------------------*
  useEffect(() => {
    let _price = isMember ? member_price : non_member_price
    let _kwh = 0
    //-- 전력
    if (chargeType === 'amount') {
      _kwh = Math.floor(Number(chargePrice) / _price)
      _price = chargePrice
    }
    //-- 전력
    if (chargeType === 'power') {
      _kwh = Number(chargePower)
      _price = _kwh * _price
    }
    //-- 완전충전 (사용되지않음)
    if (chargeType === 'full') {
      _price = fullPrice
      _kwh = Math.floor(chargePower / _price)
    }
    // console.log(chargeType)
    // console.log(_price, _kwh)
    setPrice(_price)
    setKwh(_kwh)
    dispatch(contextAction.setResult({price: _price, kwh: _kwh}))
  }, [isMember, chargeType])
  //*--------------------------------------------------*
  return (
    <App gnb={{prev: true, title: '충전 하기', home: true}}>
      <Content>
        {/* {debug([isMember, chargeType, price, kwh])} */}
        <Flex flexdirection="column" alignitems="center" justifycontent="center" style={{marginTop: 56}}>
          <Text style={{marginBottom: 55}} mode="Body_MSB" label={`“충전 시작하기" 버튼을 눌러 결제를 진행해 주세요.`} color={COLOR.gs10} />
          <section className="info">
            <Flex.SpaceBetween width="100%" style={{marginBottom: 10}}>
              <Text mode="Button_SB" label="예상 결제(충전) 금액" color={chargeType === 'amount' ? COLOR.blue30 : COLOR.blue10} />
              <Text mode="Button_SB" label={`${addComma(price)} 원`} color={chargeType === 'amount' ? COLOR.blue30 : COLOR.blue10} />
            </Flex.SpaceBetween>
            <Flex.SpaceBetween width="100%">
              <Text mode="Button_SB" label="충전량" color={chargeType === 'power' ? COLOR.blue30 : COLOR.blue10} />
              <Text mode="Button_SB" label={`${addComma(kwh)} kWh`} color={chargeType === 'power' ? COLOR.blue30 : COLOR.blue10} />
            </Flex.SpaceBetween>
          </section>
          <Text mode="Body_MSB" color={COLOR.red10} align="center">
            결제 예상 금액은 충전 완료 후 금액과 달라질 수 있습니다.
            <br /> 차액은 충전 후 부분 취소 됩니다.
          </Text>
        </Flex>
        {/* 확인 */}
        <NextButton
          className={'auto'}
          style={{width: 830, marginTop: 424}}
          label="충전 시작하기"
          onClick={() => {
            navigate(MENU.CHARGE_WAIT)
          }}
        />
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 0 auto;
  /* section */
  .info {
    width: 830px;
    margin-bottom: 60px;
    padding: 60px;
    border-radius: 40px;
    border: 2px solid ${COLOR.gs50};
    background: ${COLOR.white};
    align-items: center;
    gap: 24px;
  }
`
