/**
 * @name 타이틀
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Index() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 56}} flexdirection="column" alignitems="center" justifycontent="center">
        <Text mode="Body_MR" align="center" color={COLOR.gs10}>
          <p>
            초기 설정 금액과 최종 충전 금액이 다른 경우 자동으로 부분 취소되며,
            <br />
            정산정보를 문자로 전송합니다.
          </p>
        </Text>
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
`
