/**
 * @name 타이틀
 * @description
 */
import {Flex, Text} from 'lib'
import styled from 'styled-components'
import {COLOR} from 'styles'

export default function Index() {
  // hooks
  //*--------------------------------------------------*
  return (
    <Content>
      <Flex style={{width: '100%', marginTop: 33}} flexdirection="column" alignitems="center" justifycontent="center">
        <Text mode="Body_MR" color={COLOR.red10} label="현재 충전 중입니다." />
        <Text mode="Body_MR" color={COLOR.red10} label="충전 중 강제로 커넥터를 분리 시 사고의 위험이 있습니다." />
      </Flex>
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  display: flex;
`
