/**
 * @name 광고영역
 */
import {ad_img1, ad_img2, ad_img3, ad_img4} from 'assets/images'
import {motion, AnimatePresence} from 'framer-motion'
import {Flex, Text, useInterval} from 'lib'
import {useState} from 'react'
import styled from 'styled-components'
import {COLOR} from 'styles'
// contents

export default function Ad() {
  // hooks
  const [select, setSelect] = useState(0)
  const imageList = [ad_img1, ad_img2, ad_img3, ad_img4]
  //*--------------------------------------------------*
  useInterval(() => {
    setSelect(val => (val + 1) % imageList.length)
  }, 5000)
  //*--------------------------------------------------*
  return (
    <Content>
      <AnimatePresence>
        <motion.img
          key={select}
          src={imageList[select]}
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          exit={{opacity: 0}}
          transition={{duration: 0.6, ease: 'easeInOut'}}
          style={{position: 'absolute', top: '0', left: '0'}}
        />
      </AnimatePresence>
      {/* <aside className="gnb">
        <Flex alignitems="center" justifycontent="center" width="100%" style={{height: 60}}>
          <Text mode="Body_MB" label={`${select + 1} / ${imageList.length}`} color={COLOR.gs10} />
        </Flex>
      </aside> */}
      {/* <iframe
        width="100%"
        height="480"
        src="https://www.youtube.com/embed/Z9hRw9umAVI?controls=0&autoplay=1&mute=1&loop=1&&playlist=Z9hRw9umAVI"
        title="데이터와 전력을 연결하기위해 미국에서 넘어온 스타트업 | 크로커스 임지섭 대표"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
       */}
    </Content>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  /* position: relative; */
  display: flex;
  width: 100%;
  height: 480px;
  align-items: center;
  justify-content: center;
  /*-- 사이트맵 --*/
  img {
    width: 100%;
    height: auto;
    vertical-align: top;
    /* height: 100%; */
  }
  /*-- 네비게이션 --*/
  aside.gnb {
    position: absolute;
    z-index: 1;
    width: 95px;
    height: 60px;
    right: 0;
    top: calc(480px - 60px);
    background-color: ${COLOR.white};
  }
`
