/**
 * @name .
 * @description
 */
import {useEffect} from 'react'
import {Flex, Image, useRoutes} from 'lib'
import styled from 'styled-components'
import {App, Loading} from 'template'
import {select_member_tag_check_img1} from 'assets/svg'
import {DELAY_TIME, MENU} from 'config'
// contents

export default function Index() {
  // hooks
  const {navigate} = useRoutes()
  //*--------------------------------------------------*
  useEffect(() => {
    setTimeout(() => {
      navigate(MENU.CHARGE_TYPE)
    }, DELAY_TIME)
  }, [])
  // hooks
  //*--------------------------------------------------*
  return (
    <App>
      <Content>
        <Flex flexdirection="column" justifycontent="center" alignitems="center">
          <Image src={select_member_tag_check_img1} />
          <div style={{marginTop: 100}}>
            <Loading />
          </div>
        </Flex>
      </Content>
    </App>
  )
}
//*--------------------------------------------------*
const Content = styled.div`
  width: 1000px;
  margin: 134px auto;
`
